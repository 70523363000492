import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function Scolarships() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Стипендии`,
          `Профф`,
          `Пыть-Ях`,
          `Профессиональное образование`,
        ]}
        title='Стипендии'
      />

      <section className='flex flex-col  '>
        <h1 className=' text-center text-2xl uppercase tracking-wider text-green-800 font-bold mb-4'>
          СТИПЕНДИИ И ИНЫЕ ВИДЫ МАТЕРИАЛЬНОЙ ПОДДЕРЖКИ
        </h1>

        <div className='my-4 px-2 py-4 bg-white rounded'>
          <h2 className='mb-2'>НАЛИЧИЕ И УСЛОВИЕ ПРЕДОСТАВЛЕНИЯ СТИПЕНДИЙ:</h2>
          <p>Стипендия не предусмотрена.</p>
        </div>

        <div className='my-4 px-2 py-4 bg-white rounded'>
          <h2 className='mb-2'>ИНФОРМАЦИЯ О МЕРАХ СОЦИАЛЬНОЙ ПОДДЕРЖКИ:</h2>
          <p>
            Обучающимся предоставляются меры материальной поддержки: гибкая
            система ценообразования на образовательные услуги, рассрочка, скидка
            на последующие договора обучения по программам профессиональной
            переподготовки (до 10%); за счет использования дистанционных
            технологий обучения групп экономия затрат заказчика (обучающегося)
            на проживание и транспорт.
          </p>
        </div>
        <div className='my-4 px-2 py-4 bg-white rounded'>
          <h2 className='mb-2'>
            НАЛИЧИЕ ОБЩЕЖИТИЯ, ИНТЕРНАТА В УЧРЕЖДЕНИИ, В ТОМ ЧИСЛЕ
            ПРИСПОСОБЛЕННЫХ ДЛЯ ИСПОЛЬЗОВАНИЯ ИНВАЛИДАМИ И ЛИЦ С ОВЗ:
          </h2>
          <p>
            Общежитие отсутствует; по запросу предоставляется информация о
            бюджетном варианте размещения в гостиницах г. Пыть-Яха. Информация о
            поступлениях воспитанников образовательной организации.
            Трудоустройство выпускников образовательных программ не
            предусмотрено.
          </p>
        </div>
      </section>
    </Layout>
  );
}

export default Scolarships;
